import {authRouteConfig} from './auth';
import {initialUrl} from 'shared/constants/AppConst';
import {Navigate} from 'react-router-dom';
import Error403 from './errorPages/Error403';
import React from 'react';
import {errorPagesConfigs} from './errorPages';

import {accountPagesConfigs} from './account';
import {dashboardConfigs} from './dashboard/dashboardConfigs';
import {sendEmailConfigs} from './sendEmail/sendEmailConfigs';
import {promotionConfigs} from './promotion/promotionConfigs';
import {usersConfigs} from './users/usersConfigs';
import {settingsConfigs} from './settings';
import {partnersPagesConfigs} from './partners';
import {customersPagesConfigs} from './partners/list/customersPagesConfigs';

import {questionsConfigs} from './questions/questionsConfigs';
import {sectionsConfigs} from './sections/sectionsConfigs';
import {suggestedConfigs} from './suggested/suggestedConfigs';
import {testsConfigs} from './tests/testsConfigs';
import {ordersConfigs} from './orders/ordersConfigs';

const authorizedStructure = {
  fallbackPath: '/signin',
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...dashboardConfigs,
    ...usersConfigs,
    ...accountPagesConfigs,
    ...questionsConfigs,
    ...sectionsConfigs,
    ...suggestedConfigs,
    ...testsConfigs,
    ...settingsConfigs,
    ...ordersConfigs,
    ...partnersPagesConfigs,
    ...customersPagesConfigs,
    ...sendEmailConfigs,
    ...promotionConfigs,
  ],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: '/',
      element: <Navigate to={initialUrl} />,
    },
    {
      path: '*',
      element: <Navigate to='/error-pages/error-404' />,
    },
  ]),
};

export {authorizedStructure, unAuthorizedStructure, anonymousStructure};
