/* eslint-disable no-unused-vars */
import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {useSelector} from 'react-redux';

export default function BackDropLoading() {
  const {backDropLoading, backDropMessage} = useSelector(({common}) => common);
  // console.log('backDropLoading = ', backDropLoading);
  //   const handleClose = () => {
  //     setOpen(false);
  //   };
  //   const handleToggle = () => {
  //     setOpen(!open);
  //   };

  return (
    <div>
      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={backDropLoading}
        // onClick={handleClose}
      >
        <CircularProgress color='inherit' />
        <br />
        <h1>{backDropMessage}</h1>
      </Backdrop>
    </div>
  );
}
