import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const Orders = React.lazy(() => import('.'));

export const ordersConfigs = [
  {
    permittedRole: [RoutePermittedRole.ADMIN, RoutePermittedRole.SUPERADMIN],
    path: '/orders',
    element: <Orders />,
  },
];
