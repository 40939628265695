import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const SendEmail = React.lazy(() => import('.'));

export const sendEmailConfigs = [
  {
    permittedRole: [RoutePermittedRole.ADMIN, RoutePermittedRole.SUPERADMIN],
    path: '/sendEmail',
    element: <SendEmail />,
  },
];
