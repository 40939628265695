import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const SectionsPage = React.lazy(() => import('.'));

export const sectionsConfigs = [
  {
    permittedRole: [RoutePermittedRole.ADMIN, RoutePermittedRole.SUPERADMIN],
    path: '/sections',
    element: <SectionsPage />,
  },
];
