import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  TOGGLE_APP_DRAWER,
  UPDATING_CONTENT,
  SHOW_BACK_DROP,
  HIDE_BACK_DROP,
  MESSAGE_BACK_DROP,
  SET_SELECTED_YEAR,
} from 'shared/constants/ActionTypes';

export const fetchStart = () => {
  return (dispatch) => dispatch({type: FETCH_START});
};

export const fetchSuccess = () => {
  return (dispatch) => dispatch({type: FETCH_SUCCESS});
};
export const updatingContent = () => {
  return (dispatch) => dispatch({type: UPDATING_CONTENT});
};

export const fetchError = (error) => {
  return (dispatch) => dispatch({type: FETCH_ERROR, payload: error});
};

export const showMessage = (message) => {
  return (dispatch) => dispatch({type: SHOW_MESSAGE, payload: message});
};
export const onToggleAppDrawer = () => {
  return (dispatch) => dispatch({type: TOGGLE_APP_DRAWER});
};

export const hideMessage = () => {
  return (dispatch) => dispatch({type: HIDE_MESSAGE});
};

export const showBackDrop = () => {
  return (dispatch) => dispatch({type: SHOW_BACK_DROP});
};

export const hideBackDrop = () => {
  return (dispatch) => dispatch({type: HIDE_BACK_DROP});
};

export const messageBackDrop = (message) => {
  return (dispatch) => dispatch({type: MESSAGE_BACK_DROP, payload: message});
};

export const setSearchYear = (selectedYear) => {
  return (dispatch) =>
    dispatch({type: SET_SELECTED_YEAR, payload: selectedYear});
};
