import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const Dashboard = React.lazy(() => import('.'));

export const dashboardConfigs = [
  {
    permittedRole: [RoutePermittedRole.ADMIN, RoutePermittedRole.SUPERADMIN],
    path: '/dashboard',
    element: <Dashboard />,
  },
];
