import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const TestsPage = React.lazy(() => import('.'));

export const testsConfigs = [
  {
    permittedRole: [RoutePermittedRole.ADMIN, RoutePermittedRole.SUPERADMIN],
    path: '/tests',
    element: <TestsPage />,
  },
];
