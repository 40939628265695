export const authRole = {
  SUPERADMIN: ['MEMBER', 'ADMIN', 'SUPERADMIN', 'PARTNER', 'CUSTOMER'],
  ADMIN: ['MEMBER', 'ADMIN', 'PARTNER', 'CUSTOMER'],
  MEMBER: ['MEMBER'],
};

export const RoutePermittedRole = {
  SUPERADMIN: 'SUPERADMIN',
  ADMIN: 'ADMIN',
  MEMBER: 'MEMBER',
  PARTNER: 'PARTNER',
  CUSTOMER: 'CUSTOMER',
};

export const defaultUser = {
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: 'MEMBER',
  photoURL: '/assets/images/avatar/A11.jpg',
};

export const defaultGridConfig = {
  rowsPerPage: 100,
  pages: 100,
};

export const initialUrl = '/dashboard'; // this url will open after login
export const customerInitialUrl = '/customerDashboard'; // this url will open after login
export const partnerInitialUrl = '/partnerDashboard'; // this url will open after login

export const RETAKE_LIMIT = 3;
