import {auth, db} from '../@crema/services/auth/firebase/firebase';

import {useFirestoreConnect} from 'react-redux-firebase';
import axios from 'axios';
import {getAuth} from 'firebase/auth';
import {serverTimestamp} from 'firebase/firestore';

const COLLECTION = {
  USERS: 'users',
  PARTNERS: 'partners',
  ORDERS: 'orders',
  TESTS: 'tests',
  RETAKEHISTORY: 'retakehistory',
  EMAILTEMPLATES: 'email-templates',
  EMAILSENDHISTORY: 'email-send-history',
};

const usersRef = db.collection(COLLECTION.USERS);
const partnersRef = db.collection(COLLECTION.PARTNERS);
const retakeHistoryRef = db.collection(COLLECTION.RETAKEHISTORY);
const emailSendHistoryRef = db.collection(COLLECTION.EMAILSENDHISTORY);

export const usersCollection = {
  getStaffDataAndListener: () => {
    useFirestoreConnect([
      {
        collection: COLLECTION.USERS,
        storeAs: 'staffs',
        where: [
          'userType',
          'in',
          ['SUPERADMIN', 'ADMIN'],
          ['email', '!=', auth.currentUser.email],
        ],
      },
      {
        collection: COLLECTION.PARTNERS,
        storeAs: 'partners',
        where: [
          'userType',
          'in',
          ['PARTNER', 'CUSTOMER'],
          ['email', '!=', auth.currentUser.email],
          ['deleted', '==', false],
        ],
      },
    ]);
  },
  onGetStaffList: async (type, name) => {
    if (name == 'all') {
      useFirestoreConnect([
        {
          collection: COLLECTION.USERS,
          storeAs: 'staffs',
          where: [
            ['userType', 'in', ['SUPERADMIN', 'ADMIN']],
            ['email', '!=', auth.currentUser.email],
          ],
        },
      ]);
    } else {
      useFirestoreConnect([
        {
          collection: COLLECTION.USERS,
          storeAs: 'staffs',
          where: [
            ['userType', '==', name.toUpperCase()],
            ['email', '!=', auth.currentUser.email],
          ],
        },
      ]);
    }
  },
  onGetPartnerList: async (type, name) => {
    if (name == 'all') {
      useFirestoreConnect([
        {
          collection: COLLECTION.PARTNERS,
          storeAs: 'partners',
          where: [
            ['userType', 'in', ['PARTNER', 'CUSTOMER']],
            ['email', '!=', auth.currentUser.email],
            ['deleted', '==', false],
          ],
        },
      ]);
    } else {
      useFirestoreConnect([
        {
          collection: COLLECTION.PARTNERS,
          storeAs: 'partners',
          where: [
            ['userType', '==', name.toUpperCase()],
            ['email', '!=', auth.currentUser.email],
            ['deleted', '==', false],
          ],
        },
      ]);
    }
  },
  getEmailTemplates: async () => {
    useFirestoreConnect([
      {
        collection: COLLECTION.EMAILTEMPLATES,
        storeAs: 'emailTemplates',
        where: [['isDeleted', '==', false]],
      },
    ]);
  },
  deleteStaff: async (uid) => {
    const baseURL =
      process.env.REACT_APP_firebase_function_endpoint + '/bspApi/delete-staff';
    const auth = await getAuth();
    if (!auth.currentUser.accessToken) {
      throw new Error('Invalid user accessToken');
    }
    const requestData = {
      uid: uid,
    };

    const options = {
      headers: {
        Authorization: 'Bearer ' + auth.currentUser.accessToken,
        'Content-Type': 'application/json',
      },
    };

    return await axios.post(baseURL, requestData, options);
  },
  deletePartner: async (uid) => {
    const baseURL =
      process.env.REACT_APP_firebase_function_endpoint + '/bspApi/delete-staff';
    const auth = await getAuth();
    if (!auth.currentUser.accessToken) {
      throw new Error('Invalid user accessToken');
    }
    const requestData = {
      uid: uid,
    };

    const options = {
      headers: {
        Authorization: 'Bearer ' + auth.currentUser.accessToken,
        'Content-Type': 'application/json',
      },
    };

    return await axios.post(baseURL, requestData, options);
  },
  setDeleteFlagPartner: async (uid) => {
    const history = {
      label: 'Delete',
      datetime: serverTimestamp(),
      userId: auth.currentUser.email,
    };

    await partnersRef.doc(uid).update({deleted: true});
    await partnersRef.doc(uid).collection('history').add(history);
  },
  addStaff: async (userData) => {
    const baseURL =
      process.env.REACT_APP_firebase_function_endpoint + '/bspApi/create-staff';
    const auth = await getAuth();
    if (!auth.currentUser.accessToken) {
      throw new Error('Invalid user accessToken');
    }

    const requestData = {
      user: userData,
    };

    const options = {
      headers: {
        Authorization: 'Bearer ' + auth.currentUser.accessToken,
        'Content-Type': 'application/json',
      },
    };

    return await axios.post(baseURL, requestData, options);
  },
  addPartner: async (userData) => {
    const userInfo = await usersCollection.getUserByEmail(userData.email);

    if (userInfo.userUid) {
      return userInfo;
    }

    const baseURL =
      process.env.REACT_APP_firebase_function_endpoint + '/bspApi/create-staff';
    const auth = await getAuth();
    if (!auth.currentUser.accessToken) {
      throw new Error('Invalid user accessToken');
    }

    const requestData = {
      user: userData,
    };

    const options = {
      headers: {
        Authorization: 'Bearer ' + auth.currentUser.accessToken,
        'Content-Type': 'application/json',
      },
    };

    return await axios.post(baseURL, requestData, options);
  },
  createPartner: async (userData) => {
    const history = {
      label: 'Create',
      datetime: serverTimestamp(),
      userId: auth.currentUser.email,
    };

    await partnersRef.doc(userData.uid).set(userData);
    await partnersRef.doc(userData.uid).collection('history').add(history);
  },
  updateStaff: async (uid, updateData) => {
    await usersRef.doc(uid).update({
      firstName: updateData.firstName,
      lastName: updateData.lastName,
      phoneNumber: updateData.phoneNumber,
      initials:
        updateData.firstName[0].toUpperCase() +
        updateData.lastName[0].toUpperCase(),
      userType: updateData.userType,
    });
  },
  updatePartner: async (uid, updateData) => {
    const history = {
      label: 'Update',
      datetime: serverTimestamp(),
      userId: auth.currentUser.email,
    };

    await partnersRef.doc(uid).update({
      firstName: updateData.firstName,
      lastName: updateData.lastName,
      phoneNumber: updateData.phoneNumber,
      initials:
        updateData.firstName[0].toUpperCase() +
        updateData.lastName[0].toUpperCase(),
      userType: updateData.userType,
      nation: updateData.nation,
      region: updateData.region,
      street1: updateData.street1,
      street2: updateData.street2,
      city: updateData.city,
      businessNo: updateData.businessNo,
      postalCode: updateData.postalCode,
      memo: updateData.memo,
      customerType: updateData.customerType,
      customerScale: updateData.customerScale,
      partnerName: updateData.partnerName,
      partnerId: updateData.partnerId,
      customerPartnerId: updateData.customerPartnerId,
      timezone: updateData.timezone,
    });

    await partnersRef.doc(uid).collection('history').add(history);
  },

  getUsersDataAndListener: () => {
    useFirestoreConnect([
      {
        collection: COLLECTION.USERS,
        storeAs: 'users',
        // where: ['userType', '==', 'MEMBER'],
        orderBy: ['createdAt', 'desc'],
      },
    ]);
  },
  getUserById: async (userId) => {
    useFirestoreConnect([
      {
        collection: COLLECTION.USERS,
        doc: userId,
        storeAs: 'user',
      },
      {
        collection: `${COLLECTION.USERS}/${userId}/tests`,
        storeAs: 'userTests',
      },
      {
        collection: `${COLLECTION.USERS}/${userId}/history`,
        storeAs: 'userHistory',
        orderBy: ['createdAt', 'desc'],
      },
      {
        collection: COLLECTION.ORDERS,
        where: ['user_uid', '==', userId],
        storeAs: 'userOrders',
        orderBy: ['createdAt', 'desc'],
      },
    ]);
  },
  getUserByEmail: async (userEmail) => {
    let userData = {};

    await usersRef
      .where('email', '==', userEmail)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          userData = doc.data();
          userData.userUid = doc.id;
        });
      });

    return userData;
  },
  updateUserProfile: async (uid, updateData) => {
    try {
      await usersRef.doc(uid).update({
        firstName: updateData.firstName,
        lastName: updateData.lastName,
        phoneNumber: updateData.phoneNumber,
      });

      const historyData = {
        discription: 'Update profile',
        oldProfile: {
          firstName: updateData.firstName,
          lastName: updateData.lastName,
          phoneNumber: updateData.phoneNumber,
        },
        createdAt: new Date(),
      };

      await usersRef.doc(uid).collection('history').add(historyData);
    } catch (e) {
      return new Error('Error: Update profile');
    }
  },

  getUserRetakeHistory: async (userId, baseId) => {
    let returnData = [];
    try {
      const history = await retakeHistoryRef
        .where(`userUID`, '==', userId)
        .where(`testBaseId`, '==', baseId)
        .get();

      for (const retake of history.docs) {
        const retakeData = retake.data();

        retakeData.retakeAt = retakeData.retakeDatetime
          .toDate()
          .toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          });

        returnData.push(retakeData);
      }
    } catch (err) {
      console.log(err);
    }

    return returnData;
  },
  resetUserRetakeHistory: async (userId, baseId) => {
    let returnData = [];
    const userTestRef = db.collection(
      `${COLLECTION.USERS}/${userId}/${COLLECTION.TESTS}`,
    );

    try {
      await retakeHistoryRef
        .where(`userUID`, '==', userId)
        .where(`testBaseId`, '==', baseId)
        .where(`retakeType`, '==', `User`)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.delete();
          });
        });

      await userTestRef.doc(`${baseId}`).update({retakeCount: 0});

      await retakeHistoryRef.add({
        userUID: `${userId}`,
        testBaseId: `${baseId}`,
        retakeSeq: ``,
        retakeType: `Admin`,
        retakeUserUID: `${auth.currentUser.uid}`,
        retakeReason: `Admin reset retake.`,
        retakeIP: ``,
        retakeDatetime: serverTimestamp(),
      });
    } catch (err) {
      console.log(err);
    }

    return returnData;
  },
  checkUsersListForStudents: async (usersList) => {
    let returnData = [];
    let idx = 0;

    for (const user of usersList) {
      let temp = {...user};

      await usersRef
        .where(`email`, '==', user.studentEmail)
        .get()
        .then((querySnapshot) => {
          temp.isExist = !querySnapshot.empty;
          temp.studentUserId = temp.isExist ? querySnapshot.docs[0].id : '';
        });

      temp.id = idx;
      returnData.push(temp);
      idx++;
    }

    return returnData;
  },
  saveSendEmailResult: async (sendResult, sentTemplate) => {
    const sendHistory = {
      sentResult: sendResult,
      sentTemplate: sentTemplate,
      sendTimestamp: serverTimestamp(),
      userId: auth.currentUser.email,
    };

    await emailSendHistoryRef.doc().set(sendHistory);
  },
};
