import React from 'react';
import {Navigate} from 'react-router-dom';
import {RoutePermittedRole} from 'shared/constants/AppConst';

import Account from './MyProfile';

const Staff = React.lazy(() => import('./Staff'));
const Partner = React.lazy(() => import('./Partner'));

export const accountPagesConfigs = [
  {
    permittedRole: [RoutePermittedRole.SUPERADMIN, RoutePermittedRole.ADMIN],
    path: '/my-profile',
    element: <Account />,
  },
  {
    permittedRole: [RoutePermittedRole.SUPERADMIN, RoutePermittedRole.ADMIN],
    path: ['/account/staff/:name'],
    element: <Staff />,
  },
  {
    permittedRole: [RoutePermittedRole.SUPERADMIN, RoutePermittedRole.ADMIN],
    path: '/account/staff',
    element: <Navigate to='/account/staff/all' />,
  },
  {
    permittedRole: [RoutePermittedRole.SUPERADMIN, RoutePermittedRole.ADMIN],
    path: ['/account/partner/:name'],
    element: <Partner />,
  },
  {
    permittedRole: [RoutePermittedRole.SUPERADMIN, RoutePermittedRole.ADMIN],
    path: '/account/partner',
    element: <Navigate to='/account/partner/all' />,
  },
];
