/* eslint-disable no-unused-vars */
import React from 'react';
import {useAuthUser} from '@crema/utility/AuthHooks';
import {Formik} from 'formik';
import * as yup from 'yup';
import PersonalInfoForm from './PersonalInfoForm';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import {useSelector, useDispatch} from 'react-redux';
import {isLoaded, isEmpty} from 'react-redux-firebase';
import IntlMessages from '@crema/utility/IntlMessages';
import AppLoader from '@crema/core/AppLoader';
import {usersCollection} from '../../../../services/usersCollection';
import {showMessage} from 'redux/actions';
import AppInfoView from '@crema/core/AppInfoView';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object({
  email: yup.string().email('Invalid email format').required('Required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  phoneNumber: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
});
const PersonalInfo = () => {
  const {user} = useAuthUser();
  const profile = useSelector((state) => state.firebase.profile);
  const dispatch = useDispatch();

  if (!isLoaded(profile)) {
    return <AppLoader />;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: 550,
      }}
    >
      <Formik
        validateOnBlur={true}
        initialValues={{
          ...profile,
          photoURL: user.photoURL
            ? user.photoURL
            : '/assets/images/placeholder.jpg',
        }}
        validationSchema={validationSchema}
        onSubmit={async (data, {setSubmitting}) => {
          setSubmitting(true);
          try {
            await usersCollection.updateUserProfile(user.uid, data, profile);
            dispatch(showMessage('Success to update your profile.'));
          } catch (e) {
            console.log(e);
            dispatch(showMessage('Please, Enter all informations.'));
          }
          setSubmitting(false);
        }}
      >
        {({values, setFieldValue, errors}) => {
          return (
            <PersonalInfoForm
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
            />
          );
        }}
      </Formik>
      <AppInfoView />
    </Box>
  );
};

export default PersonalInfo;

PersonalInfo.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.string,
};
