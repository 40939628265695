import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const Promotion = React.lazy(() => import('.'));

export const promotionConfigs = [
  {
    permittedRole: [RoutePermittedRole.ADMIN, RoutePermittedRole.SUPERADMIN],
    path: '/promotion',
    element: <Promotion />,
  },
];
