import React from 'react';
import {Navigate} from 'react-router-dom';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const Practice = React.lazy(() => import('./practice'));
const Student = React.lazy(() => import('./student'));
const Credit = React.lazy(() => import('./credit'));
const PartnerStudents = React.lazy(() => import('./partnerStudent'));
const PartnerOrders = React.lazy(() => import('./partnerOrder'));
const PartnerCustomers = React.lazy(() => import('./partnerCustomer'));
const PartnerPractices = React.lazy(() => import('./partnerPractice'));
const CustomerDashboard = React.lazy(() => import('./customerDashboard'));
const PartnerDashboard = React.lazy(() => import('./partnerDashboard'));

export const customersPagesConfigs = [
  {
    permittedRole: [
      RoutePermittedRole.SUPERADMIN,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.CUSTOMER,
      RoutePermittedRole.MEMBER,
    ],
    path: '/customerDashboard',
    element: <CustomerDashboard />,
  },
  {
    permittedRole: [
      RoutePermittedRole.SUPERADMIN,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.CUSTOMER,
    ],
    path: '/practice',
    element: <Navigate to='/practice/all' />,
  },
  {
    permittedRole: [
      RoutePermittedRole.SUPERADMIN,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.CUSTOMER,
    ],
    path: ['/practice/:name'],
    element: <Practice />,
  },
  {
    permittedRole: [
      RoutePermittedRole.SUPERADMIN,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.CUSTOMER,
    ],
    path: '/student',
    element: <Navigate to='/student/all' />,
  },
  {
    permittedRole: [
      RoutePermittedRole.SUPERADMIN,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.CUSTOMER,
    ],
    path: ['/student/:name'],
    element: <Student />,
  },
  {
    permittedRole: [
      RoutePermittedRole.SUPERADMIN,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.CUSTOMER,
    ],
    path: ['/credit/history'],
    element: <Credit />,
  },
  {
    permittedRole: [
      RoutePermittedRole.SUPERADMIN,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.PARTNER,
    ],
    path: '/partnerDashboard',
    element: <PartnerDashboard />,
  },
  {
    permittedRole: [
      RoutePermittedRole.SUPERADMIN,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.PARTNER,
    ],
    path: '/partnerStudent',
    element: <Navigate to='/partnerStudent/all' />,
  },
  {
    permittedRole: [
      RoutePermittedRole.SUPERADMIN,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.PARTNER,
    ],
    path: ['/partnerStudent/:name'],
    element: <PartnerStudents />,
  },
  {
    permittedRole: [
      RoutePermittedRole.SUPERADMIN,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.PARTNER,
    ],
    path: '/partnerOrder',
    element: <Navigate to='/partnerOrder/all' />,
  },
  {
    permittedRole: [
      RoutePermittedRole.SUPERADMIN,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.PARTNER,
    ],
    path: ['/partnerOrder/:name'],
    element: <PartnerOrders />,
  },
  {
    permittedRole: [
      RoutePermittedRole.SUPERADMIN,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.PARTNER,
    ],
    path: '/partnerCustomer',
    element: <Navigate to='/partnerCustomer/all' />,
  },
  {
    permittedRole: [
      RoutePermittedRole.SUPERADMIN,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.PARTNER,
    ],
    path: '/partnerCustomer/:name',
    element: <PartnerCustomers />,
  },
  {
    permittedRole: [
      RoutePermittedRole.SUPERADMIN,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.PARTNER,
    ],
    path: '/partnerPractice',
    element: <Navigate to='/partnerPractice/all' />,
  },
  {
    permittedRole: [
      RoutePermittedRole.SUPERADMIN,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.PARTNER,
    ],
    path: '/partnerPractice/:name',
    element: <PartnerPractices />,
  },
];
