import React from 'react';
import {Navigate} from 'react-router-dom';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const Practice = React.lazy(() => import('./Practice'));
const Student = React.lazy(() => import('./Student'));

export const partnersPagesConfigs = [
  {
    //permittedRole: [RoutePermittedRole.SUPERADMIN, RoutePermittedRole.ADMIN],
    path: '/partners/practice',
    element: <Navigate to='/partners/practice/all' />,
  },
  {
    permittedRole: [RoutePermittedRole.SUPERADMIN, RoutePermittedRole.ADMIN],
    path: ['/partners/practice/:name'],
    element: <Practice />,
  },
  {
    permittedRole: [RoutePermittedRole.SUPERADMIN, RoutePermittedRole.ADMIN],
    path: '/partners/student',
    element: <Navigate to='/partners/student/all' />,
  },
  {
    permittedRole: [RoutePermittedRole.SUPERADMIN, RoutePermittedRole.ADMIN],
    path: ['/partners/student/:name'],
    element: <Student />,
  },
];
