import {FiUsers} from 'react-icons/fi';
import {
  MdOutlineAnalytics,
  MdOutlineDns,
  MdOutlineManageAccounts,
} from 'react-icons/md';
import {BsCart4, BsQuestionDiamond} from 'react-icons/bs';
import {
  RiCustomerService2Line,
  RiTodoLine,
  RiUserFollowLine,
} from 'react-icons/ri';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import {
  VscTools,
  VscPerson,
  VscOrganization,
  VscChecklist,
} from 'react-icons/vsc';
import {AiFillMail} from 'react-icons/ai';
import {RoutePermittedRole} from '../shared/constants/AppConst';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DiscountIcon from '@mui/icons-material/Discount';

const routesConfig = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    messageId: 'sidebar.admin.dashboard',
    permittedRole: [RoutePermittedRole.SUPERADMIN, RoutePermittedRole.ADMIN],
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Dashbarod',
        messageId: 'dashboard.quickStats',
        type: 'item',
        icon: <MdOutlineAnalytics />,
        url: '/dashboard',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
        ],
      },
      {
        id: 'orders',
        title: 'Orders',
        messageId: 'sidebar.orders',
        type: 'item',
        icon: <BsCart4 />,
        url: '/orders',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
        ],
      },
      {
        id: 'sendEmail',
        title: 'Send Email',
        messageId: 'sidebar.sendEmail',
        type: 'item',
        icon: <AiFillMail />,
        url: '/sendEmail',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
        ],
      },
      {
        id: 'promotion',
        title: 'Promotion',
        messageId: 'sidebar.promotion',
        type: 'item',
        icon: <DiscountIcon />,
        url: '/promotion',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
        ],
      },
    ],
  },
  {
    id: 'account',
    title: 'Account',
    permittedRole: [RoutePermittedRole.SUPERADMIN, RoutePermittedRole.ADMIN],
    messageId: 'sidebar.account',
    icon: <VscPerson />,
    type: 'collapse',
    children: [
      {
        id: 'users',
        title: 'Users',
        messageId: 'sidebar.users',
        type: 'item',
        icon: <FiUsers />,
        url: '/users',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
        ],
      },
      {
        id: 'staff',
        title: 'Staff',
        messageId: 'sidebar.staff',
        type: 'item',
        icon: <MdOutlineManageAccounts />,
        url: '/account/staff',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
        ],
      },
      {
        id: 'partner',
        title: 'Partner',
        messageId: 'sidebar.partner',
        type: 'item',
        icon: <Diversity3OutlinedIcon />,
        url: '/account/partner',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
        ],
      },
    ],
  },
  {
    id: 'partner',
    title: 'Partner',
    permittedRole: [
      RoutePermittedRole.SUPERADMIN,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.PARTNER,
    ],
    messageId: 'sidebar.regionalPartner',
    icon: <VscOrganization />,
    type: 'collapse',
    children: [
      {
        id: 'partner_dashboard',
        title: 'Dashboard',
        messageId: 'dashboard',
        type: 'item',
        icon: <MdOutlineAnalytics />,
        url: '/partnerDashboard',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
          RoutePermittedRole.PARTNER,
        ],
      },
      {
        id: 'partner_order',
        title: 'Orders',
        messageId: 'sidebar.orders',
        type: 'item',
        icon: <BsCart4 />,
        url: '/partnerOrder',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
          RoutePermittedRole.PARTNER,
        ],
      },
      {
        id: 'partner_customer',
        title: 'Regional Customer',
        messageId: 'sidebar.regionalCustomer',
        type: 'item',
        icon: <FiUsers />,
        url: '/partnerCustomer',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
          RoutePermittedRole.PARTNER,
        ],
      },
      {
        id: 'partner_practice',
        title: 'Practice',
        messageId: 'sidebar.practice',
        type: 'item',
        icon: <VscChecklist />,
        url: '/partnerPractice',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
          RoutePermittedRole.PARTNER,
        ],
      },
      {
        id: 'partner_student',
        title: 'Student',
        messageId: 'sidebar.student',
        type: 'item',
        icon: <RiUserFollowLine />,
        url: '/partnerStudent',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
          RoutePermittedRole.PARTNER,
        ],
      },
    ],
  },
  {
    id: 'customer',
    title: 'Customer',
    permittedRole: [
      RoutePermittedRole.SUPERADMIN,
      RoutePermittedRole.ADMIN,
      RoutePermittedRole.CUSTOMER,
    ],
    messageId: 'sidebar.regionalCustomer',
    icon: <VscOrganization />,
    type: 'collapse',
    children: [
      {
        id: 'customer_dashboard',
        title: 'Dashboard',
        messageId: 'dashboard',
        type: 'item',
        icon: <MdOutlineAnalytics />,
        url: '/customerDashboard',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
          RoutePermittedRole.CUSTOMER,
          RoutePermittedRole.MEMBER,
        ],
      },
      {
        id: 'customer_credit',
        title: 'Credit History',
        messageId: 'sidebar.credit.history',
        type: 'item',
        icon: <CreditCardIcon />,
        url: '/credit/history',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
          RoutePermittedRole.CUSTOMER,
        ],
      },
      {
        id: 'customer_practice',
        title: 'Practice',
        messageId: 'sidebar.practice',
        type: 'item',
        icon: <VscChecklist />,
        url: '/practice',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
          RoutePermittedRole.CUSTOMER,
        ],
      },
      {
        id: 'customer_student',
        title: 'Student',
        messageId: 'sidebar.student',
        type: 'item',
        icon: <RiUserFollowLine />,
        url: '/student',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
          RoutePermittedRole.CUSTOMER,
        ],
      },
    ],
  },
  {
    id: 'settings',
    permittedRole: [RoutePermittedRole.SUPERADMIN, RoutePermittedRole.ADMIN],
    title: 'Settings',
    messageId: 'sidebar.settings',
    icon: <VscTools />,
    type: 'collapse',
    children: [
      {
        id: 'questions',
        title: 'Questions',
        messageId: 'sidebar.questions',
        type: 'item',
        icon: <BsQuestionDiamond />,
        url: '/questions',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
        ],
      },
      {
        id: 'sections',
        title: 'Sections',
        messageId: 'sidebar.sections',
        type: 'item',
        icon: <MdOutlineDns />,
        url: '/sections',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
        ],
      },
      {
        id: 'suggested',
        title: 'Suggested',
        messageId: 'sidebar.suggested',
        type: 'item',
        icon: <RiCustomerService2Line />,
        url: '/suggested',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
        ],
      },
      {
        id: 'tests',
        title: 'Tests',
        messageId: 'sidebar.tests',
        type: 'item',
        icon: <RiTodoLine />,
        url: '/tests',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
        ],
      },
      {
        id: 'products',
        title: 'Products',
        messageId: 'sidebar.products',
        type: 'item',
        icon: <RiTodoLine />,
        url: '/settings/products',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
        ],
      },
      {
        id: 'Notice',
        title: 'Notice',
        messageId: 'sidebar.notice',
        type: 'item',
        icon: <AnnouncementIcon />,
        url: '/utils/Notice',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
        ],
      },
      {
        id: 'math.question.maker',
        title: 'Math Question Maker',
        messageId: 'sidebar.math.question.maker',
        type: 'item',
        icon: <RiTodoLine />,
        url: '/settings/math-question-maker',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
        ],
      },
      {
        id: 'practice',
        title: 'Practice',
        messageId: 'sidebar.practice',
        type: 'item',
        icon: <VscChecklist />,
        url: '/partners/practice',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
        ],
      },
      {
        id: 'student',
        title: 'Student',
        messageId: 'sidebar.student',
        type: 'item',
        icon: <RiUserFollowLine />,
        url: '/partners/student',
        permittedRole: [
          RoutePermittedRole.SUPERADMIN,
          RoutePermittedRole.ADMIN,
        ],
      },
      // {
      //   id: 'questions-table-template',
      //   title: 'Questions Table',
      //   messageId: 'sidebar.questions.table',
      //   type: 'item',
      //   icon: <BiAlignLeft />,
      //   url: '/questions/QuestionsTableTemplate',
      // },
      // {
      //   id: 'questions-grid-template',
      //   title: 'Questions Grid',
      //   messageId: 'sidebar.questions.grid',
      //   type: 'item',
      //   icon: <BiAlignLeft />,
      //   url: '/questions/QuestionsGridTemplate',
      // },
      //
    ],
  },
];
export default routesConfig;
