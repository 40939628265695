import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const Users = React.lazy(() => import('.'));
const UserDetailView = React.lazy(() => import('./UserDetail/UserDetailView'));
// import UserDetailView from './UserDetail/UserDetailView';

export const usersConfigs = [
  {
    permittedRole: [RoutePermittedRole.ADMIN, RoutePermittedRole.SUPERADMIN],
    path: '/users',
    element: <Users />,
  },
  {
    permittedRole: [RoutePermittedRole.SUPERADMIN],
    path: '/user/:userId',
    element: <UserDetailView />,
  },
];
