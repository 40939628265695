import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const Products = React.lazy(() => import('./Products'));
const MathQuestionMaker = React.lazy(() => import('./MathQuestionMaker'));
const Notice = React.lazy(() => import('pages/utils/Notice'));

export const settingsConfigs = [
  {
    permittedRole: [RoutePermittedRole.ADMIN, RoutePermittedRole.SUPERADMIN],
    path: '/settings/products',
    element: <Products />,
  },
  {
    permittedRole: [RoutePermittedRole.ADMIN, RoutePermittedRole.SUPERADMIN],
    path: '/utils/Notice',
    element: <Notice />,
  },
  {
    permittedRole: [RoutePermittedRole.ADMIN, RoutePermittedRole.SUPERADMIN],
    path: '/settings/math-question-maker',
    element: <MathQuestionMaker />,
  },
];
