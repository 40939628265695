import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const QuestionsPage = React.lazy(() => import('.'));

export const questionsConfigs = [
  {
    permittedRole: [RoutePermittedRole.ADMIN, RoutePermittedRole.SUPERADMIN],
    path: '/questions',
    element: <QuestionsPage />,
  },
];
