/* eslint-disable no-unused-vars */
import React from 'react';
import Button from '@mui/material/Button';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {grey} from '@mui/material/colors';
import {Fonts} from 'shared/constants/AppEnums';
import {
  initialUrl,
  customerInitialUrl,
  partnerInitialUrl,
} from 'shared/constants/AppConst';
import AppAnimate from '@crema/core/AppAnimate';
import IntlMessages from '@crema/utility/IntlMessages';
import {ReactComponent as Logo} from '../../../assets/icon/403.svg';
import {useTheme} from '@mui/material';
import {useAuthUser} from '@crema/utility/AuthHooks';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Error403 = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {user, isAuthenticated} = useAuthUser();

  let roleInitialUrl = '';

  if (isAuthenticated && user?.role === 'CUSTOMER') {
    roleInitialUrl = customerInitialUrl;
  } else if (isAuthenticated && user?.role === 'PARTNER') {
    roleInitialUrl = partnerInitialUrl;
  } else if (isAuthenticated && user?.role === 'SUPERADMIN') {
    roleInitialUrl = initialUrl;
  } else if (isAuthenticated && user?.role === 'ADMIN') {
    roleInitialUrl = initialUrl;
  } else if (isAuthenticated && user?.role === 'MEMBER') {
    roleInitialUrl = initialUrl;
  }

  navigate(roleInitialUrl);

  const onGoBackToHome = () => {
    // navigate(initialUrl);
    navigate(roleInitialUrl);
  };

  return (
    <AppAnimate animation='transition.slideUpIn' delay={200}>
      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={true}
        // onClick={handleClose}
      >
        <CircularProgress color='inherit' />
        <br />
      </Backdrop>
      {/* <Box
        sx={{
          py: {xl: 8},
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            mb: {xs: 4, xl: 8},
            width: '100%',
            maxWidth: {xs: 200, sm: 300, xl: 706},
            '& svg': {
              width: '100%',
              maxWidth: 400,
            },
          }}
        >
          <Logo fill={theme.palette.primary.main} />
        </Box>
        <Box sx={{mb: {xs: 4, xl: 5}}}>
          <Box
            variant='h3'
            sx={{
              mb: {xs: 3, xl: 4},
              fontSize: {xs: 20, md: 24},
              fontWeight: Fonts.MEDIUM,
            }}
          >
            Unauthorized
          </Box>
          <Box
            sx={{
              mb: {xs: 4, xl: 5},
              color: grey[600],
              fontSize: 16,
              fontWeight: Fonts.MEDIUM,
            }}
          >
            <Typography>You are not authorized for this page</Typography>
          </Box>
          <Button
            variant='contained'
            color='primary'
            sx={{
              fontWeight: Fonts.MEDIUM,
              fontSize: 16,
              textTransform: 'capitalize',
            }}
            onClick={onGoBackToHome}
          >
            <IntlMessages id='error.goBackToHome' />
          </Button>
        </Box>
      </Box> */}
    </AppAnimate>
  );
};

export default Error403;
